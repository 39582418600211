import { createRouter, createWebHistory } from 'vue-router'
import layouts from '@/views/layout'

const routes = [
  {
    path: '/',
    name: 'home',
    component: layouts,
    meta: {
      title: '数据大屏'
    },
    children: [
      {
        path: '/',
        name: '/',
        component: () => import('../views/home/index.vue'),
        meta: {
          title: '数据大屏'
        }
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
