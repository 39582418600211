<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
.app {
  --background-theme-color: brown;
}
.swiper {
  --swiper-theme-color: var(--background-theme-color); /* 设置Swiper风格 */
  --swiper-navigation-color: var(
    --background-theme-color
  ); /* 单独设置按钮颜色 */
  --swiper-navigation-size: 20px; /* 设置按钮大小 */
}
</style>
