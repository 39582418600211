<template>
  <div class="app">
    <!-- <div class="no_nav" style="position: relative">
      招商热线：<span>400-991-7979</span>
    </div> -->
    <!-- <div class="pon_nav">
      <heads></heads>
    </div> -->

    <router-view />
    <!-- <footers></footers> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from 'vue'
// import Heads from '@/components/layoutHead/index.vue'
// import Footers from '@/components/layoutFooter/index.vue'
export default defineComponent({
  name: 'LAYOUT',
  components: {
    // Heads
    // Footers
  },
  setup() {
    onMounted(() => {})

    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange
    }
  }
})
// {
//   name: 'HelloWorld',
//   components: {
//     Swiper,
//     SwiperSlide
//   },
//   props: {
//     msg: String
//   },
//   mounted() {
//     $('.material-icons').snabbt({
//       position: [150, 0, 0],
//       rotation: [0, 0, Math.PI]
//     })
//   },
//   methods: {
//     onSwiper: function (swiper) {
//       console.log(swiper)
//     },
//     onSlideChange: () => {
//       console.log('slide change')
//     }
//   }
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app {
  padding: 0;
  margin: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.pon_nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
}
.no_nav {
  display: block;
  text-align: right;
  font-size: 0.12rem;
  padding: 0.1rem 0.05rem;
  background-color: brown;
  color: #fff;
}
@media screen and (max-width: 800px) {
  .no_nav {
    display: none;
  }
}
</style>
